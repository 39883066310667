import { withFronteggApp } from '@frontegg/nextjs/pages';
import '@mantine/carousel/styles.css';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Analytics, SupportInformation } from '@zorro/shared/ui';
import { useAppProgressBar } from '@zorro/shared/utils';
import { Theme } from '@zorro/zorro-ui-design';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ReactNode, useState } from 'react';
import { AppConfigurationProvider } from '~/context/AppConfigurationContext';
import { ScenarioCalculatedDataProvider } from '~/context/ScenarioCalculatedData';
import { SheetDataProvider } from '~/context/SheetDataContext';

interface TornadoAppProps extends AppProps {
  Component: AppProps['Component'] & {
    getLayout?: (page: ReactNode) => ReactNode;
  };
}

function TornadoApp({
  Component,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  pageProps: { session, ...pageProps },
}: TornadoAppProps) {
  useAppProgressBar();

  // eslint-disable-next-line react/hook-use-state
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            staleTime: 60_000,
            retry: false,
          },
        },
      })
  );

  return (
    <>
      <Head>
        <title>Welcome to Zorro Quote!</title>
      </Head>

      <QueryClientProvider client={queryClient}>
        <AppConfigurationProvider>
          <SheetDataProvider>
            <ScenarioCalculatedDataProvider>
              <MantineProvider theme={Theme}>
                <SupportInformation />
                <Analytics applicationName="tornado" />
                <Notifications position="top-right" />
                <Component {...pageProps} />
              </MantineProvider>
            </ScenarioCalculatedDataProvider>
          </SheetDataProvider>
        </AppConfigurationProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default withFronteggApp(TornadoApp, {
  lazyLoadAdminPortal: true,
  authOptions: {
    keepSessionAlive: true,
  },
});
