import {
  AllowanceUnit,
  ClassType,
  FamilyUnitDistribution,
  GeoDistribution,
} from '@zorro/clients';
import { ERROR_MESSAGES } from '@zorro/shared/utils';
import { ZorroError } from '@zorro/types';
import { Allowance, AllowanceModel } from '~/types/allowance';
import {
  ClassValues,
  CostUnitResolver,
  EmployeeData,
  EmployeeScenario,
  FamilyUnit,
} from '~/types/sheet';
import { getValueOrFirst } from '~/utils/helpers';

export const EMPLOYEE_MAX_AGE = 64;
export const EMPLOYEE_MIN_AGE = 18;
export const CHILD_COUNT_GENERAL_AVERAGE = 1.6;

export type FullAllowanceModel = {
  allowanceModel: Map<
    string,
    {
      age: string;
      classValue?: string;
      [columnName: string]: unknown;
    }[]
  >;
  selectedClass?: ClassType;
};

export type StandardizedEmployeeAllowance = {
  employee: EmployeeData;
  standardizedEmployeeAllowance: number;
};
type StandardizedAllowance = Map<string, StandardizedEmployeeAllowance>;

function getStateCostUnitFactor(
  employee: EmployeeData,
  geoDistribution: GeoDistribution,
  familyType: FamilyUnit
): number {
  if (geoDistribution === GeoDistribution.OFF) {
    return 1;
  }
  const familyUnitStateFactors: Record<FamilyUnit, Record<string, number>> = {
    [FamilyUnit.EMPLOYEE_ONLY]: {},
    [FamilyUnit.EMPLOYEE_SPOUSE]: {},
    [FamilyUnit.EMPLOYEE_CHILD]: {
      NY: 1.7,
      VT: 1.93,
      default: 1,
    },
    [FamilyUnit.FAMILY]: {
      NY: 2.85,
      VT: 2.81,
      default: 1,
    },
  };
  return (
    familyUnitStateFactors[familyType][employee.stateCode] ||
    familyUnitStateFactors[familyType]['default']
  );
}

function getChildCount(
  childCount: number,
  familyUnits: FamilyUnitDistribution
): number {
  return familyUnits === FamilyUnitDistribution.EIGHT
    ? childCount
    : CHILD_COUNT_GENERAL_AVERAGE;
}

export function calculateTotalCostUnits(
  employee: EmployeeData,
  costUnitResolver: CostUnitResolver,
  geoDistribution: GeoDistribution,
  familyUnits: FamilyUnitDistribution
): number {
  const employeeCostUnits = costUnitResolver.getEmployeeCostUnits(
    employee.age,
    employee.stateCode
  );
  const childCostUnits = costUnitResolver.getChildCostUnits(employee.stateCode);

  switch (employee.familyUnit) {
    case FamilyUnit.EMPLOYEE_ONLY: {
      return employeeCostUnits;
    }

    case FamilyUnit.EMPLOYEE_SPOUSE: {
      return employeeCostUnits * 2;
    }

    case FamilyUnit.EMPLOYEE_CHILD: {
      const stateFactorChild = getStateCostUnitFactor(
        employee,
        geoDistribution,
        FamilyUnit.EMPLOYEE_CHILD
      );
      if (stateFactorChild !== 1) {
        return employeeCostUnits * stateFactorChild;
      }
      return (
        employeeCostUnits +
        getChildCount(employee.childCount, familyUnits) * childCostUnits
      );
    }

    case FamilyUnit.FAMILY: {
      const stateFactorFamily = getStateCostUnitFactor(
        employee,
        geoDistribution,
        FamilyUnit.FAMILY
      );
      if (stateFactorFamily !== 1) {
        return employeeCostUnits * stateFactorFamily;
      }
      return (
        employeeCostUnits * 2 +
        getChildCount(employee.childCount, familyUnits) * childCostUnits
      );
    }

    default: {
      throw new ZorroError(
        ERROR_MESSAGES.getInvalidFamilyUnitErrorMessage(employee.familyUnit)
      );
    }
  }
}

export function getAllowanceColumnName(
  familyUnit: FamilyUnit,
  childCount: number
) {
  if (
    [FamilyUnit.EMPLOYEE_ONLY, FamilyUnit.EMPLOYEE_SPOUSE].includes(familyUnit)
  ) {
    return familyUnit;
  }
  return `${familyUnit}${
    childCount === 0 ? '' : childCount === 3 ? 'max' : childCount
  }`;
}

export function calculateAllowance(
  employee: EmployeeData,
  fullAllowanceModel: FullAllowanceModel,
  geoDistribution: GeoDistribution,
  employeeId: string,
  isEmployeeOnlyMode: boolean = false
) {
  const ageForModel = Math.min(
    Math.max(employee.age, EMPLOYEE_MIN_AGE),
    EMPLOYEE_MAX_AGE
  );

  const allowanceRow = getValueOrFirst(
    fullAllowanceModel.allowanceModel,
    geoDistribution === GeoDistribution.AREA
      ? employee.ratingArea
      : employee.stateCode
  )?.find((allowance) => {
    let classValueMatches = false;
    if (fullAllowanceModel.selectedClass) {
      const classValue =
        fullAllowanceModel.selectedClass === ClassType.WAGE_TYPE
          ? employee.wageType === 'Salaried'
            ? 'Salary'
            : employee.wageType
          : employee.employmentType;
      classValueMatches = allowance.classValue === classValue;
    } else {
      classValueMatches = true;
    }
    return allowance.age === ageForModel.toString() && classValueMatches;
  });
  if (!allowanceRow) {
    throw new ZorroError(
      ERROR_MESSAGES.getNoAllowanceFoundErrorMessage(employeeId)
    );
  }

  return allowanceRow[
    isEmployeeOnlyMode
      ? getAllowanceColumnName(FamilyUnit.EMPLOYEE_ONLY, 0)
      : getAllowanceColumnName(employee.familyUnit, employee.childCount)
  ] as number;
}

export const getStandardizedEmployeeAllowances = (
  fullAllowanceModel: FullAllowanceModel,
  employeeData: Map<string, EmployeeData>,
  employeeScenarios: EmployeeScenario[],
  geoDistribution: GeoDistribution
): StandardizedAllowance => {
  function calculateStandardizedAllowance(
    employee: EmployeeData,
    employeeId: string
  ) {
    const calculatedAllowance = calculateAllowance(
      employee,
      fullAllowanceModel,
      geoDistribution,
      employeeId
    );
    const calculatedPremium =
      employeeScenarios.find((scenario) => scenario.employeeId === employeeId)
        ?.expectedCost ?? 0;
    return Math.min(calculatedAllowance, calculatedPremium);
  }

  return new Map(
    Array.from(employeeData, ([employeeId, employee]) => {
      return [
        employeeId,
        {
          employee,
          standardizedEmployeeAllowance: calculateStandardizedAllowance(
            employee,
            employeeId
          ),
        },
      ];
    })
  );
};

export const convertAllowanceModelToPercent = (
  allowance: Allowance,
  employeeAveragePremium: number,
  childrenAveragePremium: number
) => {
  return {
    employeeOnly: (100 * allowance.employeeOnly) / employeeAveragePremium,
    employeeAndSpouse:
      (100 * allowance.employeeAndSpouse) / (2 * employeeAveragePremium),
    employeeAndChildren:
      (100 * allowance.employeeAndChildren) /
      (employeeAveragePremium + childrenAveragePremium),
    employeeAndFamily:
      (100 * allowance.employeeAndFamily) /
      (2 * employeeAveragePremium + childrenAveragePremium),
  };
};

export const convertAllowanceModelToDollars = (
  allowance: Allowance,
  employeeAveragePremium: number,
  childrenAveragePremium: number
) => {
  return {
    employeeOnly: (allowance.employeeOnly * employeeAveragePremium) / 100,
    employeeAndSpouse:
      (allowance.employeeAndSpouse * 2 * employeeAveragePremium) / 100,
    employeeAndChildren:
      (allowance.employeeAndChildren *
        (employeeAveragePremium + childrenAveragePremium)) /
      100,
    employeeAndFamily:
      (allowance.employeeAndFamily *
        (2 * employeeAveragePremium + childrenAveragePremium)) /
      100,
  };
};

export function generateFullAllowanceModel(
  allowanceModel: AllowanceModel,
  costUnitResolver: CostUnitResolver,
  costUnitValuesByState: Map<string, number>,
  employeeAveragePremium: number,
  childrenAveragePremium: number
): FullAllowanceModel {
  function generateFullAllowanceModelForClass(
    classAllowance: Allowance,
    stateCode: string,
    classValue?: string
  ) {
    const employeeCostUnitsByAge = new Map(
      Array.from(
        { length: EMPLOYEE_MAX_AGE - EMPLOYEE_MIN_AGE + 1 },
        (_, index) => index + EMPLOYEE_MIN_AGE
      ).map((age) => [
        age.toString(),
        costUnitResolver.getEmployeeCostUnits(age, stateCode),
      ])
    );
    const childCostUnits = costUnitResolver.getChildCostUnits(stateCode);
    const adjustedCostUnitValue = costUnitValuesByState.get(stateCode) ?? 0;
    let workingAllowance = classAllowance;
    if (allowanceModel.unit === AllowanceUnit.DOLLAR) {
      workingAllowance = convertAllowanceModelToPercent(
        classAllowance,
        employeeAveragePremium,
        childrenAveragePremium
      );
    }
    const employeeAllowancePercent = workingAllowance.employeeOnly / 100;
    const spouseAllowancePercent =
      Math.max(
        2 * workingAllowance.employeeAndSpouse - workingAllowance.employeeOnly,
        0
      ) / 100;
    const childAllowancePercent =
      Math.max(
        workingAllowance.employeeAndChildren *
          (employeeAveragePremium + childrenAveragePremium) -
          workingAllowance.employeeOnly * employeeAveragePremium,
        0
      ) /
      childrenAveragePremium /
      100;
    return Array.from(employeeCostUnitsByAge, ([age, employeeCostUnits]) => {
      function calculateFamilyUnitAllowance(
        familyUnit: FamilyUnit,
        childCount: number
      ) {
        switch (familyUnit) {
          case FamilyUnit.EMPLOYEE_ONLY: {
            return (
              employeeCostUnits *
              adjustedCostUnitValue *
              employeeAllowancePercent
            );
          }
          case FamilyUnit.EMPLOYEE_SPOUSE: {
            return (
              employeeCostUnits *
              adjustedCostUnitValue *
              (employeeAllowancePercent + spouseAllowancePercent)
            );
          }
          case FamilyUnit.EMPLOYEE_CHILD: {
            const adjustedChildCount = getChildCount(
              childCount,
              allowanceModel.familyUnits
            );
            let costUnits =
              employeeCostUnits * employeeAllowancePercent +
              adjustedChildCount * childCostUnits * childAllowancePercent;
            if (allowanceModel.geoDistribution !== GeoDistribution.OFF) {
              if (stateCode === 'NY') {
                costUnits =
                  employeeCostUnits *
                  (employeeAllowancePercent + 0.7 * childAllowancePercent);
              } else if (stateCode === 'VT') {
                costUnits =
                  employeeCostUnits *
                  (employeeAllowancePercent + 0.93 * childAllowancePercent);
              }
            }
            return adjustedCostUnitValue * costUnits;
          }
          case FamilyUnit.FAMILY: {
            const adjustedChildCount = getChildCount(
              childCount,
              allowanceModel.familyUnits
            );
            let costUnits =
              employeeCostUnits * employeeAllowancePercent +
              employeeCostUnits * spouseAllowancePercent +
              adjustedChildCount * childCostUnits * childAllowancePercent;
            if (allowanceModel.geoDistribution !== GeoDistribution.OFF) {
              if (stateCode === 'NY') {
                costUnits =
                  employeeCostUnits *
                  (employeeAllowancePercent +
                    spouseAllowancePercent +
                    0.85 * childAllowancePercent);
              } else if (stateCode === 'VT') {
                costUnits =
                  employeeCostUnits *
                  (employeeAllowancePercent +
                    spouseAllowancePercent +
                    0.81 * childAllowancePercent);
              }
            }
            return adjustedCostUnitValue * costUnits;
          }
          default: {
            throw new ZorroError(
              ERROR_MESSAGES.getInvalidFamilyUnitErrorMessage(familyUnit)
            );
          }
        }
      }

      function calculateAllowanceRounded(
        familyUnit: FamilyUnit,
        childCount: number
      ) {
        const allowance = calculateFamilyUnitAllowance(familyUnit, childCount);
        return Math.round(allowance);
      }

      function generateAllowanceEntry(
        familyUnit: FamilyUnit,
        children: number
      ) {
        return {
          [getAllowanceColumnName(familyUnit, children)]:
            calculateAllowanceRounded(familyUnit, children),
        };
      }

      return {
        age,
        classValue,
        ...generateAllowanceEntry(FamilyUnit.EMPLOYEE_ONLY, 0),
        ...generateAllowanceEntry(FamilyUnit.EMPLOYEE_SPOUSE, 0),
        ...generateAllowanceEntry(FamilyUnit.EMPLOYEE_CHILD, 1),
        ...generateAllowanceEntry(FamilyUnit.EMPLOYEE_CHILD, 2),
        ...generateAllowanceEntry(FamilyUnit.EMPLOYEE_CHILD, 3),
        ...generateAllowanceEntry(FamilyUnit.FAMILY, 1),
        ...generateAllowanceEntry(FamilyUnit.FAMILY, 2),
        ...generateAllowanceEntry(FamilyUnit.FAMILY, 3),
      };
    });
  }

  function generateAllowanceEntriesForState(stateCode: string) {
    const classValues = ClassValues.filter(
      (classValue) => classValue.type === allowanceModel.selectedClass
    );
    const allowanceEntries = generateFullAllowanceModelForClass(
      allowanceModel.mainAllowance,
      stateCode,
      classValues.find((classValue) => classValue.order === 0)?.displayName
    );
    if (allowanceModel.useClasses) {
      allowanceEntries.push(
        ...generateFullAllowanceModelForClass(
          allowanceModel.secondaryAllowance,
          stateCode,
          classValues.find((classValue) => classValue.order === 1)?.displayName
        )
      );
    }
    return allowanceEntries;
  }

  const stateAllowanceEntries = new Map(
    [...costUnitValuesByState.keys()].map((stateKey) => [
      stateKey,
      generateAllowanceEntriesForState(stateKey),
    ])
  );

  return {
    allowanceModel: stateAllowanceEntries,
    selectedClass: allowanceModel.useClasses
      ? allowanceModel.selectedClass
      : undefined,
  };
}
