import {
  ClassType,
  EmploymentType,
  MetalLevel,
  NetworkType,
  QuotePreferenceDto,
  WageType,
} from '@zorro/clients';
import { $enum } from 'ts-enum-util';

export interface IPlan {
  planName: string;
  planId: string;
}

export interface EmployeeScenario extends IPlan {
  idFromEmployer: string;
  employeeId: string;
  stateCode: string;
  ratingArea: string;
  carrier: string;
  premium: number;
  expectedCost: number;
}

export type ClassValue = {
  type: ClassType;
  displayName: string;
  order: number;
};

export const ClassValues: ClassValue[] = [
  {
    type: ClassType.EMPLOYMENT_TYPE,
    displayName: 'Full Time',
    order: 0,
  },
  {
    type: ClassType.EMPLOYMENT_TYPE,
    displayName: 'Part Time',
    order: 1,
  },
  {
    type: ClassType.WAGE_TYPE,
    displayName: 'Salary',
    order: 0,
  },
  {
    type: ClassType.WAGE_TYPE,
    displayName: 'Hourly',
    order: 1,
  },
];

export const getEmploymentTypeLabel = (
  employmentType?: EmploymentType | null
): string => {
  return $enum.mapValue(employmentType).with({
    [EmploymentType.FULL_TIME]: 'Full Time',
    [EmploymentType.PART_TIME]: 'Part Time',
    [$enum.handleUnexpected]: '',
    [$enum.handleUndefined]: '',
    [$enum.handleNull]: '',
  });
};

export const getWageTypeLabel = (wageType?: WageType | null): string => {
  return $enum.mapValue(wageType).with({
    [WageType.SALARY]: 'Salary',
    [WageType.HOURLY]: 'Hourly',
    [$enum.handleUnexpected]: '',
    [$enum.handleUndefined]: '',
    [$enum.handleNull]: '',
  });
};

export const getMetalLevelLabel = (metalLevel?: MetalLevel | null): string => {
  return $enum.mapValue(metalLevel).with({
    [MetalLevel.EXPANDED_BRONZE]: 'Expanded Bronze',
    [MetalLevel.CATASTROPHIC]: 'Catastrophic',
    [MetalLevel.PLATINUM]: 'Platinum',
    [MetalLevel.BRONZE]: 'Bronze',
    [MetalLevel.SILVER]: 'Silver',
    [MetalLevel.GOLD]: 'Gold',
    [$enum.handleUnexpected]: '',
    [$enum.handleUndefined]: '',
    [$enum.handleNull]: '',
  });
};

export const getNetworkTypeLabel = (networkType: NetworkType): string => {
  return $enum.mapValue(networkType).with({
    [NetworkType.INDEMNITY]: 'INDEMNITY',
    [NetworkType.EPO]: 'EPO',
    [NetworkType.HMO]: 'HMO',
    [NetworkType.POS]: 'POS',
    [NetworkType.PPO]: 'PPO',
    [$enum.handleUnexpected]: '',
  });
};

export enum FamilyUnit {
  EMPLOYEE_ONLY = 'EE',
  EMPLOYEE_SPOUSE = 'ES',
  EMPLOYEE_CHILD = 'EC',
  FAMILY = 'FA',
}

export interface EmployeeData extends IPlan {
  name?: string;
  carrier: string;
  premium: number | null;
  allowance: number | null;
  familyUnit: FamilyUnit;
  age: number;
  spouseAge: number | null;
  childCount: number;
  employmentType?: string;
  wageType?: string;
  isEmployeeWaived: boolean;
  wage: number;
  lowCostSilverPlanPremium?: number;
  stateCode: string;
  ratingArea: string;
  isMedicareEligible: boolean;
}

export interface Plan {
  id: string;
  name: string;
  carrier: string;
  deductible: number | null;
  medicalMoop: number | null;
  pcpCost: string;
  specialistCost: string;
  genericDrugsCost: string;
  numberOfEmployees?: number;
}

export interface PlanDiversityEntry {
  id: string;
  carrier: string;
  type: string;
}

export interface PlanDiversity {
  current: PlanDiversityEntry[];
  zorro: PlanDiversityEntry[];
}

export interface Fees {
  pepm: number;
  fixed: number;
}

export interface Params {
  showAffordability: boolean;
}

export enum ParamName {
  EXCLUDE_AFFORDABILITY = 'exclude_affordability',
}

export const DEFAULT_STATE_KEY = 'All states';

export interface CostUnitResolver {
  getChildCostUnits(stateCode: string): number;
  getEmployeeCostUnits(age: number, stateCode: string): number;
}

// Pretend that this is called QuoteSession
export interface SheetData {
  quoteId: string | null;
  employerName: string;
  employerId: string;
  scenarios: Map<string, EmployeeScenario[]>;
  employeeData: Map<string, EmployeeData>;
  plans: Record<string, Plan>;
  planDiversity: PlanDiversity;
  totalFees: number;
  ageCostUnits: string[][];
  statePriceFactors: Record<string, number>;
  params?: {
    showAffordability?: boolean;
  };
  quotePreferences?: QuotePreferenceDto | null;
  hasQuoteChanged: boolean;
}

export enum RangeName {
  EMPLOYEES = 'employees',
  PLANS = 'plans',
  FEES = 'fees',
  PLANS_DIVERSITY = 'plans diversity',
  AGE_COST_UNITS = 'age cost units',
  PARAMS = 'params',
}

export enum ScenarioName {
  LOW_COST_SILVER = 'Low Cost Silver',
  ON_MARKET_LCSP = 'On Market LCSP',
}

export enum PlanDiversityType {
  CURRENT = 'current',
  INDIVIDUAL_MARKET = 'individual market',
}
