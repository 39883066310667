import { useAuth } from '@frontegg/nextjs';
import { useLocalStorage } from '@mantine/hooks';
import { useMonolithQuery } from '@zorro/shared/utils';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SheetData } from '~/types/sheet';

type StateContextType = {
  isSheetDataLoading: boolean;
  sheetData: SheetData | null;
  removeSheetData: () => void;
  quoteSessionId?: string;
};

export const initialSheetDataContext: StateContextType = {
  isSheetDataLoading: true,
  sheetData: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeSheetData: () => {},
};

export const SheetDataContext = createContext<StateContextType>(
  initialSheetDataContext
);

export function useSheetData() {
  return useContext(SheetDataContext);
}

type ContextProps = {
  children: ReactNode;
};

export function SheetDataProvider({ children }: ContextProps) {
  const { isAuthenticated } = useAuth();
  const [parsedSheetData, setParsedSheetData] = useState<SheetData | null>(
    null
  );

  const [quoteSessionId, setQuoteSessionId] = useLocalStorage({
    key: 'quoteSessionId',
  });

  const { data: quoteSession, isLoading } = useMonolithQuery({
    method: 'quoteSessionControllerFindOne',
    params: isAuthenticated && quoteSessionId && [quoteSessionId],
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function parseSheetData(sheetDataObj: any) {
    if (!sheetDataObj) return;

    const dataToProcess = sheetDataObj.data || sheetDataObj;

    if (dataToProcess.employeeData) {
      dataToProcess.employeeData = new Map(
        Object.entries(dataToProcess.employeeData)
      );
    }

    if (dataToProcess.scenarios) {
      dataToProcess.scenarios = new Map(
        Object.entries(dataToProcess.scenarios)
      );
    }

    setParsedSheetData(dataToProcess as SheetData);
  }

  useEffect(() => {
    if (quoteSession && !isLoading) {
      parseSheetData(quoteSession.quoteData);
    }
  }, [quoteSession, isLoading]);

  const removeSheetData = async () => {
    setQuoteSessionId('');
  };

  return (
    <SheetDataContext.Provider
      value={{
        isSheetDataLoading: isLoading,
        sheetData: parsedSheetData,
        removeSheetData,
        quoteSessionId,
      }}
    >
      {children}
    </SheetDataContext.Provider>
  );
}
